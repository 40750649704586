import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

const useOutsideClickListener = (ref, props) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target) && props.isOpen) {
      props.onClickOutsideAction(event)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

const OutsideClickListener = props => {
  const wrapperRef = useRef(null)
  useOutsideClickListener(wrapperRef, props)

  return (
    <div ref={wrapperRef} className={props.className}>
      {props.children}
    </div>
  )
}

OutsideClickListener.propTypes = {
  children: PropTypes.element.isRequired,
}

export default OutsideClickListener
