import React from 'react'

const Footer = () => {
  return (
    <footer className="py-2 sm:py-4 bg-gls-blue-900 text-white">
      <div className="max-w-5xl mx-auto px-4">
        <div className="flex flex-wrap items-center justify-between text-xs">
          <div className="w-full md:flex-1 text-center md:text-left">
            <span>
              Copyright © {new Date().getFullYear()} Mediterranea Express s.r.l.
            </span>
            <span className="px-3 opacity-50">•</span>
            <a
              href="/privacy_medex.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gls-yellow hover:underline"
            >
              Informativa Privacy
            </a>
            <span className="px-3 opacity-50">•</span>
            <a
              href="/privacy_green_pass.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gls-yellow hover:underline"
            >
              Privacy Green Pass
            </a>
            <span className="px-3 opacity-50">•</span>
            <a
              href="/cookie_policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gls-yellow hover:underline"
            >
              Cookie Policy
            </a>
          </div>
          <div className="w-full md:w-auto text-center md:text-right">
            Sviluppato da{' '}
            <a
              href="http://www.f2.net"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gls-yellow hover:underline"
            >
              F2.net
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
