import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Header from './header'
import Footer from './footer'

import faviconImg from '../images/favicon.ico'

const iubendaImplementation = `
<script type="text/javascript">
var _iub = _iub || [];
_iub.csConfiguration = {"consentOnContinuedBrowsing":false,"floatingPreferencesButtonDisplay":"anchored-center-right","invalidateConsentWithoutLog":true,"perPurposeConsent":true,"siteId":2635873,"whitelabel":false,"cookiePolicyId":37326707,"lang":"it","cookiePolicyUrl":"https://mediterraneaexpress.com/cookie_policy.pdf", "banner":{ "acceptButtonDisplay":true,"closeButtonDisplay":false,"customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center","rejectButtonDisplay":true }};
</script>
<script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>`

const Layout = ({ children, isHome = false }) => {
  return (
    <div className="layout antialiased text-gray-800">
      <Helmet>
        <link rel="icon" href={faviconImg} />
      </Helmet>
      <div className="wrapper">
        <Header />
        <div className={`${isHome ? '' : 'max-w-5xl mx-auto mb-16'}`}>
          <main className="content">{children}</main>
        </div>
        <div className="push"></div>
      </div>
      <Footer />

      <div dangerouslySetInnerHTML={{ __html: iubendaImplementation }}></div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
