import React, { useState } from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import { FaUser, FaBars, FaTimes, FaCaretDown } from 'react-icons/fa'

import OutsideClickListener from './OutsideClickListener'
import glsLogo from '../images/gls_logo_2021.svg'
import glsLogoNegative from '../images/gls_logo_2021_negative.svg'

const menuLinks = [
  {
    id: 1,
    name: 'Home',
    url: '/',
  },
  {
    id: 2,
    name: 'Chi siamo',
    children: [
      {
        id: 1,
        name: 'Mediterranea Express',
        url: '/chi-siamo',
      },
      {
        id: 2,
        name: 'Corporate Governance',
        url: '/corporate-governance',
      },
      {
        id: 7,
        name: 'Lavora con noi',
        url: '/lavora-con-noi',
      },
    ],
  },
  {
    id: 3,
    name: 'Servizi',
    children: [
      {
        id: 1,
        name: 'Nazionali',
        type: 'title',
      },
      {
        id: 11,
        name: 'National Express',
        url: '/servizi/national-express',
      },
      {
        id: 12,
        name: 'Safe Plus',
        url: '/servizi/safe-plus',
      },
      { id: 100, type: 'separator' },
      {
        id: 2,
        name: 'Internazionali',
        type: 'title',
      },
      {
        id: 21,
        name: 'Parcel',
        url: '/servizi/parcel',
      },
      {
        id: 22,
        name: 'Express',
        url: '/servizi/express',
      },
      {
        id: 23,
        name: 'Logistics',
        url: '/servizi/logistics',
      },
      { id: 200, type: 'separator' },
      {
        id: 31,
        name: 'Servizi accessori',
        url: '/servizi/servizi-accessori',
      },
    ],
  },
  {
    id: 4,
    name: 'News',
    url: '/news',
  },
  {
    id: 5,
    name: 'FAQ',
    url: '/faq',
  },
  {
    id: 6,
    name: 'Contatti',
    url: '/contatti',
  },
]

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const [openSubmenu, setOpenSubmenu] = useState(null)

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu)
  }

  const handleCloseAll = (event) => {
    event.preventDefault()
    setOpenSubmenu(null)
  }

  const handleOpenSubmenu = (id) => (event) => {
    if (openSubmenu === id) {
      handleCloseAll(event)
    } else {
      setOpenSubmenu(id)
    }
  }

  return (
    <header>
      <style>
        {`
        .gls-logo-scroll, .small-logo {
          display: none;
        }
        @media(min-width: 640px) {
          .gls-logo-scroll, .small-logo {
            display: block;
          }
        }
        .sticky-selector > div {
          position: relative;
          z-index: 1001;
        }
        .small-logo {
          opacity: 0;
          transform: translateY(-20px);
          height: 0;
          transition: all 750ms ease;
        }
        .sticky-element .small-logo {
          opacity: 1;
          transform: translateY(0);
          height: auto;
        }
        @media(min-width: 640px) {
          .menu {
            transition: transform 300ms ease;
            transform: translateX(-100px);
          }
        }
        .sticky-element .menu {
          transform: translateX(0);
        }
      `}
      </style>

      <div className="top-header bg-gls-blue-900 py-2 md:py-0">
        <div className="max-w-5xl mx-auto px-4">
          <div className="flex flex-row items-center justify-between w-full">
            <Link to="/" className="text-white">
              <img
                src={glsLogoNegative}
                className="w-24 sm:w-32 md:w-48"
                alt="GLS"
              />
            </Link>
            <div className="flex flex-row items-center justify-end">
              <button
                onClick={handleOpenMenu}
                className="btn btn-blue block sm:hidden p-2 text-white rounded"
              >
                {openMenu ? <FaTimes /> : <FaBars />}
              </button>
              <Link
                to="/login"
                className="btn btn-blue flex flex-row items-center justify-between ml-4 py-1 px-4 text-white font-thin rounded"
              >
                <FaUser className="mr-2" />
                Accedi
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Sticky
        className="sticky-selector"
        stickyClassName="sticky-element z-50"
        stickyStyle={{ zIndex: 1001 }}
      >
        <div
          className={`bottom-header w-full ${
            openMenu ? 'block' : 'hidden'
          } sm:block bg-white z-10`}
        >
          <div className="max-w-5xl mx-auto px-4 flex items-center">
            <Link to="/" className="gls-logo-scroll text-white">
              <img src={glsLogo} className="small-logo w-20 mr-6" alt="GLS" />
            </Link>
            <div className="menu whitespace-no-wrap flex flex-col sm:flex-row items-start sm:items-center justify-start sm:justify-start py-2 sm:py-0 w-full">
              {menuLinks.map((link) => {
                return link.url ? (
                  <Link
                    to={link.url}
                    key={link.id}
                    className="w-full sm:w-auto py-1 sm:pt-5 sm:px-3 md:px-4 sm:pb-4 text-gls-blue-600 hover:text-gls-blue-700 font-semibold border-b-4 border-transparent"
                    activeClassName="xs:text-gls-yellow sm:text-gls-blue-700 sm:border-gls-yellow"
                  >
                    {link.name}
                  </Link>
                ) : (
                  <OutsideClickListener
                    key={link.id}
                    isOpen={openSubmenu === link.id}
                    onClickOutsideAction={handleCloseAll}
                    className="relative w-full sm:w-auto z-20"
                  >
                    <React.Fragment>
                      <button
                        onClick={handleOpenSubmenu(link.id)}
                        className={`flex flex-row items-center justify-start w-full py-1 sm:pt-5 sm:px-3 md:px-4 sm:pb-4 ${
                          openSubmenu === link.id
                            ? 'text-gls-blue-700'
                            : 'text-gls-blue-600'
                        } hover:text-gls-blue-700 font-semibold border-b-4 border-transparent focus:outline-none`}
                      >
                        {link.name}
                        <FaCaretDown
                          className={`ml-1 ${
                            openSubmenu === link.id ? 'rotate-180' : 'rotate-0'
                          } transform transition duration-500 ease-in-out`}
                        />
                      </button>
                      <div
                        className={`submenu flex flex-col align-start justify-center sm:absolute sm:top-0 sm:mt-16 transform transition-all duration-300 ease-in-out origin-top ${
                          openSubmenu === link.id
                            ? 'opacity-1 sm:scale-y-100 translate-y-0 h-auto pb-4 sm:pt-2'
                            : 'opacity-0 sm:scale-y-0 -translate-y-2 h-0'
                        } px-4 z-10 sm:w-48 bg-white sm:rounded-b sm:shadow-xl`}
                        style={{ zIndex: 1001 }}
                      >
                        {link.children.map((child) => {
                          return (
                            <React.Fragment key={child.id}>
                              {child.type === 'title' && (
                                <div
                                  className={`${
                                    openSubmenu === link.id ? 'block' : 'hidden'
                                  } pb-1 text-xs text-gray-500`}
                                >
                                  {child.name}
                                </div>
                              )}
                              {child.type === 'separator' && (
                                <hr
                                  className={`${
                                    openSubmenu === link.id ? 'block' : 'hidden'
                                  } my-2`}
                                />
                              )}
                              {child.url && (
                                <Link
                                  to={child.url}
                                  className={`${
                                    openSubmenu === link.id ? 'block' : 'hidden'
                                  } py-1 text-sm text-gls-blue-600 hover:text-gls-blue-700`}
                                >
                                  {child.name}
                                </Link>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </div>
                    </React.Fragment>
                  </OutsideClickListener>
                )
              })}
            </div>
          </div>
        </div>
      </Sticky>
    </header>
  )
}

export default Header
